import _ from 'lodash'

const xy = (index: number, width: number) => {
  const x = index % width
  const y = Math.floor(index / width)
  return [x, y]
}

export type GridCell = {
  index: number
  neighbors: Record<number, number>
  visited: boolean
}

const intKeys = (obj: Record<number, any>) => {
  return Object.keys(obj).map((n) => parseInt(n))
}

export const makeNeighbors = (x: number, y: number, width: number) => {
  return [
    x !== 0 ? y * width + (x - 1) : null,
    x !== width - 1 ? y * width + (x + 1) : null,
    y !== 0 ? (y - 1) * width + x : null,
    y !== width - 1 ? (y + 1) * width + x : null,
  ].filter((i) => i !== null)
}

export const generateMaze = (width: number, players: number) => {
  const grid: GridCell[] = _.range(0, width * width).map((index) => {
    const [x, y] = xy(index, width)
    const neighbors = makeNeighbors(x, y, width).map((i) => [i, 1 + Math.floor(Math.random() * players)])
    return { index, neighbors: Object.fromEntries(neighbors), visited: false }
  })

  const start = 0
  //const goal = 24

  const stack = [start]

  while (stack.length !== 0) {
    const index = stack.pop()!
    const node = grid[index]

    if (node.visited) continue

    // mark node as visited
    node.visited = true

    // pick a random neighbor that is not visited
    const cands: any = intKeys(node.neighbors).filter((n) => !grid[n].visited)
    if (!cands.length) continue

    const next = cands[Math.floor(cands.length * Math.random())]

    // remove the wall between them
    node.neighbors[next] = 0
    grid[next].neighbors[index] = 0

    // push neighbor to stack
    stack.push(...cands)
  }

  return {
    ball: 0,
    goal: width * width - 1,
    grid,
  }
}
